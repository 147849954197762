import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Registration from '../views/Registration.vue'
import UserChart from '../views/UserChart.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/userChart/:id',
    name: 'UserChart',
    component: UserChart
  },
  {
    path: '/checkKey/:key',
    name: 'CheckKey',
    component: () => import('../views/CheckRegKey.vue')
  },
  {
    path: '/artikelliste',
    name: 'Artikelliste',
    component: () => import('../views/Artikelliste.vue')
  },
  {
    path: '/artikel/:id',
    name: 'Artikel',
    component: () => import('../views/Artikel.vue')
  },
  {
    path: '/chart',
    name: 'Chart',
    component: () => import('../views/Chart.vue')
  },
  {
    path: '/benutzeroverview',
    name: 'Benutzeroverview',
    component: () => import('../views/Benutzeroverview.vue')
  },
  {
    path: '/benutzer/:id',
    name: 'Benutzer',
    component: () => import('../views/Benutzer.vue')
  },
  {
    path: '/anmeldedaten',
    name: 'Anmeldedaten',
    component: () => import('../views/Anmeldedaten.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})



export default router
