import axios from 'axios'

// eslint-disable-next-line
export class DataController {
  constructor () {
    console.debug('data loadeding')
    this.data.session = this.ReadData('vilna-session') || false
    console.debug('data controller loaded')
  }

  appVersion = 0.1

  cacheData = {}
  data = {}
  syncTo = false
  sidTo = false
  focusOnScanFieldMode = true
  lastError = false

  async API (endpoint, data) {
    var ai = axios.create({
      baseURL: 'https://app.vilna.de/api', // https://app.vilna.de/api  http://dw033n//dev/daw_vilna/api  http://dw047n/dev/daw_vilna/api  http://dw176n/dev/daw_vilna/api
      headers: {
        source: 'Android-App',
        session: (this.data.session || {}).session || false
      }
    })

    var res = false
    try {
      if (data) {
        res = await ai.post(endpoint, data)
      } else {
        res = await ai.get(endpoint)
      }
    } catch (e) {
      // console.log('axios error', e)
      return false
    }

    if (res.status === 200 && res.data.error === undefined) {
      return res.data
    } else {
      // alert(endpoint + ' ' + JSON.stringify(data) + ' ' + (res.data.error || res))
      this.lastError = res.data.error
      this.ShowMessage(res.data.error.split('[')[0], false, 'error')
      console.error('request error', endpoint, data, res)
      return false
    }
  }

  async GetUsers () {
    var res = await this.API('/users')
    // console.log('users', res)
    return res
  }

  async GetUserByID (id) {
    var res = await this.API('/user/' + id)
    return res
  }

  WriteData (storageId, data, skip = false) {
    data = data || {}
    this.data[storageId] = data
    if (!skip) {
      try {
        localStorage.setItem(storageId, JSON.stringify(data))
      } catch (e) {
        console.log('local storage overflow. Data was not persisted', data)
      }
    }
  }

  ReadData (storageId) {
    return JSON.parse(localStorage.getItem(storageId))
  }

  async Login (loginData) {
    // console.log('loginData', loginData)
    let session = await this.API('/auth', loginData)
    // console.log('session', session)
    if (!session) {
      return false
    }
    this.WriteData('vilna-session', session)
    this.data.session = session
    return session
  }

  async Logout () {
    if (!this.data.session) {
      return true
    }
    var res = await this.API('/deauth', {})
    if (res) {
      this.data.session = ''
      localStorage.removeItem('vilna-session')
    }
    return true
  }

  async Registration (data) {
    var res = await this.API('/registration', data)
    // console.log('registration response', res)
    return res
  }

  async CheckRegistrationKey (key) {
    var res = await this.API('/checkRegistrationKey/' + key)
    // console.log('check registration response', res)
    return res
  }

  async GetStatTemplates() {
    var res = await this.API('/statTemplates')
    // console.log('statTemplates', res)
    return res
  }
  
  async GetStatTemplateByID(id) {
    var res = await this.API('/statTemplate/' + id)
    // console.log('statTemplate', res)
    return res
  }
  
  async GetTemplateStatValues(stat_id, companyId, userId) {
    var res = await this.API('/getTemplateStatValues/' + stat_id + '/' + companyId + '/' + userId)
    return res
  }
  
  async AddDataToChart(data) {
    var res = await this.API('/addDataToChart', data)
    // console.log('data', data)
    return res
  }

  async InviteUser(mail) {
    var res = await this.API('/inviteUser', mail)
    // console.log('Response invite user', res)
    return res
  }

  async ChangeUserProperties(data) {
    var res = await this.API('/editUser', data)
    return res
  }

  async CheckCurrentPassword(passwordData) {
    var res = await this.API('/checkPassword', passwordData)
    console.log('check current password', res)
    if (res && res.status && res.status === 'ok') {
      return true
    } else {
      return false
    }
  }

  async GetUserStatRights(userId) {
    var res = await this.API('/userRights/' + userId)
    return res
  }

  async SetUserRights(data) {
    var res = await this.API('/setUserRights', data)
    if (res && res.status && res.status === 'ok') {
      return true
    } else {
      return false
    }
  }

  async EditStatDataPoint(data) {
    var res = await this.API('/editDataPoint', data)
    return res
  }
  
  async DeleteStatDataPoint(data) {
    var res = await this.API('/deleteDataPoint', data)
    return res
  }

  ShowMessage (txt, locked, type = 'normal') {
    let modal = document.querySelector('div.app-modal')
    modal.classList.add('visible')
    let modalMessage = modal.querySelector('#app-modal-message')
    modalMessage.innerHTML = txt
    if (type === 'error') {
      modalMessage.style.color = 'red'
    } else if (type === 'success') {
      modalMessage.style.color = 'green'
    }
    if (!locked) {
      modal.querySelector('#app-modal-close').classList.remove('hidden')
    } else {
      modal.querySelector('#app-modal-close').classList.add('hidden')
    }
  }
}
