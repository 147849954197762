<template>
    <div class="app-modal">
        <div class="modal-background">
            <div id="app-modal-container" class="modal-container">
                <div id="app-modal-header" class="modal-header">
                <font-awesome-icon id="app-modal-close" class="svg" icon="times" size="2x" @click="closeModal" />
                </div>
                <div id="app-modal-body" class="modal-body">
                <p id="app-modal-message" class="modal-message"></p>
                </div>
                <div id="app-modal-footer" class="modal-footer"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Util from '../mixins/util'

export default {
    name: 'Modal',
    data () {
        return {
        }
    },
    created () {
        window.addEventListener('scroll', this.handleModalScroll('app-modal'))
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleModalScroll('app-modal'))
    },
    mounted: function () {

    },
    methods: {
        closeModal: function () {
            var modal = document.querySelector('.app-modal')
            modal.classList.remove('visible')
        }
    },
    mixins: [Util]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.app-modal {
    display: none;
    z-index: 1;
}
.app-modal.visible {
    display: block;
}
.modal-container {
    z-index: 10;
    border: 2px solid black;
    background-color: #ffffff;
    height: 25vh;
    width: 75%;
    align-self: center;
    padding: 1vh 2vh;
    border-radius: 20px;
}
.modal-background {
    z-index: 10;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(50,50,50,0.5);
    display: flex;
    justify-content: center;
}
.modal-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
}
.modal-header svg {
    cursor: pointer;
    align-self: center;
    color: #263c8e;
}
.modal-body {
    overflow: auto;
    height: 15vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.modal-message {
    font-size: 20px;
    align-self: center;
    margin: 0;
}
.modal-footer {
    height: 4vh;
}
</style>
