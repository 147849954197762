<template>
  <div class="background-image">
    <appModal></appModal>
    <div class="registration-body">
      <div class="registration" v-if="showRegistration">
        <div class="registration-control">
          <div id="btn"></div>
          <button type="button" class="toggle-button" @click="showCompanyForm">Unternehmen</button>
          <button type="button" class="toggle-button" @click="showOwnerForm">Besitzer</button>
        </div>
        <div class="error-message-container">
          <p v-if="isError">{{errorMessage}}</p>
        </div>
        <div class="company-container">
          <div class="row">
            <div class="six columns">
              <div class="input-div one">
                <div>
                  <h5>*Firmenname</h5>
                  <input class="input" type="text" id="company-name" v-model="companyName" @focus="focusFunction" @blur="blurFunction">
                </div>
              </div>
            </div>
            <div class="six columns">
              <div class="input-div two">
                <div>
                  <h5>*Straße</h5>
                  <input class="input" type="text" id="company-street" v-model="companyStreet" @focus="focusFunction" @blur="blurFunction">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="six columns">
              <div class="input-div two">
                <div>
                  <h5>*Postleitzahl</h5>
                  <input class="input" type="text" id="company-zip" v-model="companyZip" @focus="focusFunction" @blur="blurFunction">
                </div>
              </div>
            </div>
            <div class="six columns">
              <div class="input-div two">
                <div>
                  <h5>*Stadt</h5>
                  <input class="input" type="text" id="company-city" v-model="companyCity" @focus="focusFunction" @blur="blurFunction">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="six columns">
              <div class="input-div two">
                <div>
                  <h5>*Telefon</h5>
                  <input class="input" type="text" id="company-phone" v-model="companyPhone" @focus="focusFunction" @blur="blurFunction">
                </div>
              </div>
            </div>
            <div class="six columns">
              <div class="input-div two">
                <div>
                  <h5>Webseite</h5>
                  <input class="input" type="text" id="company-website" v-model="companyWebsite" @focus="focusFunction" @blur="blurFunction">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="owner-container">
          <div class="row">
            <div class="six columns">
              <div class="input-div one">
                <div>
                  <h5>*Vorname</h5>
                  <input class="input" type="text" id="owner-firstname" v-model="ownerFirstname" @focus="focusFunction" @blur="blurFunction">
                </div>
              </div>
            </div>
            <div class="six columns">
              <div class="input-div two">
                <div>
                  <h5>*Nachname</h5>
                  <input class="input" type="text" id="owner-lastname" v-model="ownerLastname" @focus="focusFunction" @blur="blurFunction">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="six columns">
              <div class="input-div two">
                <div>
                  <h5>*E-Mail</h5>
                  <input class="input" type="text" id="owner-mail" v-model="ownerMail" @focus="focusFunction" @blur="blurFunction">
                </div>
              </div>
            </div>
            <div class="six columns"></div>
          </div>
          <div class="row">
            <div class="twelve columns">
              <h5 id="salutation-header">*Anrede</h5>
              <div class="salutation-container">
                <div class="option-container">
                  <input type="radio" id="salutation-women" value="0" v-model="ownerSalutation">
                  <label for="salutation-women">Frau</label>
                </div>
                <div class="option-container">
                  <input type="radio" id="salutation-man" value="1" v-model="ownerSalutation">
                  <label for="salutation-man">Herr</label>
                </div>
                <div class="option-container">
                  <input type="radio" id="salutation-divers" value="2" v-model="ownerSalutation">
                  <label for="salutation-divers">Divers</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="six columns">
            <div class="input-div one">
              <div>
                <h5>*Passwort</h5>
                <input class="input" type="password" id="password" v-model="password" @focus="focusFunction" @blur="blurFunction">
              </div>
            </div>
          </div>
          <div class="six columns">
            <div class="input-div two">
              <div>
                <h5>*Passwort wiederholen</h5>
                <input class="input" type="password" id="password-repeat" v-model="passwordRepeat" @focus="focusFunction" @blur="blurFunction">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="twelve columns required-fields-message-container">
            <p>Die mit * markierten Felder sind Pflichtfelder.</p>
          </div>
        </div>
        <div class="row">
          <div class="six columns">
            <button type="button" @click="toLogin" class="btn back" id="login">Login</button>
          </div>
          <div class="six columns">
            <button @click="register" type="button" class="btn" id="login">Registrieren</button>
          </div>
        </div>
      </div>
      <div class="success-message-container" v-else>
        <div class="row">
          <div class="twelve columns">
            <p class="success-message">Die Anmeldung war erfolgreich.</p>
            <p class="success-message"> Sie erhalten in Kürze eine E-Mail mit einem Password und Aktivierungslink.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '../mixins/util'
import Modal from '../components/Modal.vue'

export default {
  data: () =>({
    name: 'Registrierung',
    errorMessage: '',
    isError: false,
    showRegistration: true,
    message: '',
    companyName: '',
    companyStreet: '',
    companyZip: '',
    companyCity: '',
    companyPhone: '',
    companyWebsite: '',
    ownerFirstname: '',
    ownerLastname: '',
    ownerMail: '',
    ownerSalutation: '0',
    password: '',
    passwordRepeat: ''
  }),
  mounted: function () {
  },
  methods: {
    register: async function () {
      this.errorMessage = ''
      if (this.isRegistrationDataValid()) {
        // Send data to database
        let result = await window.dataController.Registration(
          {
            companyName: this.companyName,
            companyStreet: this.companyStreet,
            companyZip: this.companyZip,
            companyCity: this.companyCity,
            companyPhone: this.companyPhone,
            companyWebsite: this.companyWebsite,
            ownerFirstname: this.ownerFirstname,
            ownerLastname: this.ownerLastname,
            ownerMail: this.ownerMail,
            ownerSalutation: this.ownerSalutation,
            password: this.password,
            passwordRepeat: this.passwordRepeat
          }
        )
        if (result && result.status && result.status === 'ok') {
          this.isError = false
          this.showRegistration = false
          this.message = ''
          this.resetRegistrationForm()
        } else {
          this.isError = true
          this.showRegistration = true
          this.message = result.error
        }
        console.log('message', this.message)
      } else {
        this.isError = true
        this.errorMessage = 'Bitte füllen Sie die erforderlichen Felder aus.'
      }
    },
    toLogin: function () {
      this.$router.push({name: 'Login'})
    },
    isRegistrationDataValid: function () {
      if (
        this.companyName &&
        this.companyStreet &&
        this.companyZip &&
        this.companyCity &&
        this.companyPhone &&
        this.ownerFirstname &&
        this.ownerLastname &&
        this.ownerMail &&
        this.password &&
        this.passwordRepeat &&
        this.ownerSalutation
      ) {
          return true
        } else {
          false
        }
    },
    resetRegistrationForm: function () {
      this.companyName = ''
      this.companyStreet = ''
      this.companyZip = ''
      this.companyCity = ''
      this.companyPhone = ''
      this.companyWebsite = ''
      this.ownerFirstname = ''
      this.ownerLastname = ''
      this.ownerMail = ''
      this.ownerSalutation = '0'
      this.password = ''
      this.passwordRepeat = ''
      this.showOwnerForm()
    },
    showOwnerForm: function () {
      var button = document.querySelector('#btn')
      var companyForm = document.querySelector('.company-container')
      var ownerForm = document.querySelector('.owner-container')
      button.style.left = '50%'
      companyForm.style.display = 'none'
      ownerForm.style.display = 'block'
    },
    showCompanyForm: function () {
      var button = document.querySelector('#btn')
      var companyForm = document.querySelector('.company-container')
      var ownerForm = document.querySelector('.owner-container')
      button.style.left = '0'
      companyForm.style.display = 'block'
      ownerForm.style.display = 'none'
    }
  },
  components: {
    appModal: Modal
  },
  mixins: [Util]
}
</script>
<style scoped>
.background-image {
  background-image: url('/chart.jpg') ;
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.registration-body {
  background-color:rgba(255,255,255,0.8);
  border-radius:25px;
  padding: 25px;
  width: 60%;
  align-self: center;
  box-shadow: 2px 3px 10px 2px black;
}
.registration {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.registration-control {
  width: 80%;
  margin: 35px auto;
  position: relative;
  box-shadow: 0 0 20px 9px #ffffff;
  border-radius: 30px;
}
.toggle-button {
  color: #ffffffff;
  margin: 0;
  width: 50%;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
}
#btn {
  top: 0;
  left: 0;
  position: absolute;
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, #092A67, #476DB4);
  border-radius: 30px;
  transition: .5s;
}
.owner-container {
  display: none;
}
.owner-container,
.company-container {
  height: 30vh;
}
#user,
#pass {
  width: 100%;
  align-self: center;
}
button.pass {
  display: inline-block;
  border: 0;
  color: #092A67;
  padding: 0;
  margin: 0;
}
button.pass :hover {
  color: #476DB4;
}
button#login, button.pass > u {
  transition: all 0.6s ease;
}
div.link-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
div.input-div {
  position: relative;
  margin: 15px 0;
  padding: 5px 0;
  border-bottom: 2px solid #999;
}
.input-div:after, .input-div:before {
  content: '';
  position: absolute;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #092A67;
  transition: .3s;
}
.input-div:after {
  right: 50%;
}
.input-div:before {
  left: 50%;
}
div.input-div.focus .icon i {
  color: #092A67;
}
.input-div.focus div h5 {
  top: -5px;
  font-size: 15px;
}
.input-div.focus:after, .input-div.focus:before {
  width: 50%;
}
div.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.icon i {
  color: #999;
  transition: .3s;
}
div.input-div > div {
  position: relative;
  height: 45px;
}
div.input-div > div h5 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  transition: 0.3s;
}
div.registration-body h5#salutation-header {
  margin-top: 15px;
  color: #999;
  font-size: 18px;
  transition: 0.3s;
}
.input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: bold;
  color: #555;
}
.input:focus {
  border: none;
}
.btn {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  margin: 4rem 0 1rem 0;
  font-size: 1.2rem;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #092A67, #476DB4);
  cursor: pointer;
  color: #ffffffff;
  text-transform: uppercase;
  background-size: 200%;
  transition: .5s;
}
.btn.back {
  background-image: linear-gradient(to right, #F26627, #F9A36B);
}
.btn:hover {
  background-position: right;
}
.salutation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.option-container input,
.option-container label {
  display: inline-block;
  margin: 0;
}
.option-container label {
  margin-left: 15px;
}
div.error-message-container {
  text-align: center;
}
div.error-message-container p {
  color: red;
}
div.required-fields-message-container p {
  color: #999;
}
p.success-message {
  margin: 0;
  color: green;
  font-size: 22px;
}
div.success-message-container {
  text-align: center;
}
@media (max-width: 576px) {
  .registration-control {
    margin: 0 auto;
  }
  .registration-body {
    width: 90%;
  }
  .owner-container,
  .company-container {
    height: 40vh;
  }
}
</style>
