export default {
  data () {
    return {
    }
  },
  methods: {
    navigateDependingOnSession: function () {
      var isLogged = localStorage.getItem('isLogged')
      var currentRouteName = this.$route.name
      if (isLogged) {
        if (currentRouteName) {
          this.navigate(currentRouteName)
        } else {
          this.navigate('Dashboard')
        }
      } else {
        this.navigate('Login')
      }
    },
    navigate: function (routeName) {
      this.$router.push({name: routeName})
    },
    parseAsDateTime: function (dateTimeString) {
      var parsedDate = ''
      if (dateTimeString && dateTimeString !== '') {
        if (this.isDate(dateTimeString)) {
          var newDate = new Date(dateTimeString)
          parsedDate = newDate.toLocaleDateString('de-DE') + ' ' + newDate.toLocaleTimeString('de-DE')
        } else {
          parsedDate = 'Fehlerhaftes Datumsformat'
        }
      }
      return parsedDate
    },
    parseAsDate: function (dateTimeString) {
      var parsedDate = ''
      if (dateTimeString && dateTimeString !== '') {
        if (this.isDate(dateTimeString)) {
          var newDate = new Date(dateTimeString)
          parsedDate = newDate.toLocaleDateString('de-DE')
        } else {
          parsedDate = 'Fehlerhaftes Datumsformat'
        }
      }
      return parsedDate
    },
    parseAsTime: function (dateTimeString) {
      var parsedDate = ''
      if (dateTimeString && dateTimeString !== '') {
        if (this.isDate(dateTimeString)) {
          var newDate = new Date(dateTimeString)
          parsedDate = newDate.toLocaleTimeString('de-DE')
        } else {
          parsedDate = 'Fehlerhaftes Datumsformat'
        }
      }
      return parsedDate
    },
    parseDateFromGermanToEnglish: function (input) {
      var dateSplit = input.split('.')
      return new Date(dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0]);
    },
    parseDateFromEnglishToGermanString: function (input) {
      var dateSplit = input.split('-')
      return dateSplit[2] + '.' + dateSplit[1] + '.' + dateSplit[0];
    },
    isDate: function (date) {
      return (new Date(date) !== 'Invalid Date') && !isNaN(new Date(date))
    },
    focusFunction: function (event) {
      var parent = event.target.parentNode.parentNode
      parent.classList.add('focus')
    },
    blurFunction: function (event) {
      var parent = event.target.parentNode.parentNode
      if (event.target.value === '') {
        parent.classList.remove('focus')
      }
    },
    handleModalScroll (className) {
      this.scrolled = window.scrollY > 0
      var modalContainer = document.querySelector('.' + className)
      if (modalContainer) {
          if (this.scrolled && window.scrollY > 100) {
              modalContainer.style.position = 'fixed'
              modalContainer.style.top = '0px'
              modalContainer.style.marginTop = '0px'
          } else {
              modalContainer.style.top = '0px'
          }
      }
  },
  }
}
