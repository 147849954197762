<template>
    <div class="content" id="user-chart">
        <appSideNavigation></appSideNavigation>
        <appModal></appModal>
        <appEditStatDataModal :show="showEditStatDataModal" :statDataPoint="statDataPoint" :statValueType="statValueType"></appEditStatDataModal>
        <appDeleteStatDataModal :show="showDeleteStatDataModal" :statDataPoint="statDataPoint"></appDeleteStatDataModal>
        <div class="main-content-container">
            <div class="site-header">
                <h3>{{name}}</h3>
            </div>
            <div class="row" v-if="statTemplate">
                <div class="row-chart">
                    <h4 style="text-align: center;">{{statTemplate.name}}</h4>
                    <LineChart v-if='JSON.parse(statTemplate.config).chartType === "line"' :chartData="JSON.parse(statTemplate.config).chart" :options="JSON.parse(statTemplate.config).options ? JSON.parse(statTemplate.config).options : {}"/>
                    <PieChart v-else-if='JSON.parse(statTemplate.config).chartType === "pie"' :chartData="JSON.parse(statTemplate.config).chart" :options="JSON.parse(statTemplate.config).options ? JSON.parse(statTemplate.config).options : {}"/>
                    <BarChart v-else-if='JSON.parse(statTemplate.config).chartType === "bar"' :chartData="JSON.parse(statTemplate.config).chart" :options="JSON.parse(statTemplate.config).options ? JSON.parse(statTemplate.config).options : {}"/>
                </div>
                <div class="row-chart">
                    <h4>Beschreibung</h4>
                    <p>{{statTemplate.description}}</p>
                </div>
                    <div v-if="statTemplate.write==='1'">
                        <div class="row row-chart" v-if="!statTemplate.combined">
                        <h4>Daten hinzufügen</h4>
                        <div class="six columns">
                            <div class="input-div one">
                                <div v-if="statTemplate.stat_unit==='1'">
                                    <h5>*Umsatz</h5>
                                    <input class="input" type="number" id="stat-value" v-model="statValue" @focus="focusFunction" @blur="blurFunction">
                                </div>
                                <div v-else-if="statTemplate.stat_unit==='2'">
                                    <h5>*Zufriedenheit [{{statTemplate.unit_symbol}}]</h5>
                                    <input class="input" type="number" id="stat-value" min="0" max="100" step="1" v-model="statValue" @focus="focusFunction" @blur="blurFunction">
                                </div>
                                <div v-else>
                                    <h5>*Anzahl Personen</h5>
                                    <input class="input" type="number" id="stat-value" min="0" step="1" v-model="statValue" @focus="focusFunction" @blur="blurFunction">
                                </div>
                            </div>
                        </div>
                        <div class="six columns">
                            <div class="input-div two focus">
                                <div>
                                    <h5 ref="dateInputPlaceholder" id="date-input-placeholder">*Datum</h5>
                                    <input ref="statDate" class="input" type="date" id="stat-date" v-model="statDate" @focus="focusFunction" @blur="blurFunction" @change="manageTextVisibility">
                                </div>
                            </div>
                        </div>
                        <div class="twelve columns form-submit">
                            <button @click="addDataToChart" class="button-primary">Speichern</button>
                        </div>
                    </div>
                    <div class="row row-chart" v-else>
                        <div class="twelve columns">
                            <h4>Informationen zu Daten hinzufügen</h4>
                            <p>Wenn Sie zu diesem Graphen Daten hinzufügen möchten, können Sie dies über die Detailansicht(en) der nachfolgenden Graphen erledigen.</p>
                            <p class="graph-info" v-for="(dataset, index) in JSON.parse(statTemplate.config).chart.datasets" :key="dataset.borderColor">Graph {{index + 1}}: {{dataset.label}}</p>
                        </div>
                    </div>
                </div>
                <div class="row-chart" v-if="statTemplate.write==='1'">
                    <h4>Daten bearbeiten</h4>
                    <div v-if="statTemplate.statData.length > 0" class="table">
                        <div class="theader">
                            <div class="table_header">Datum</div>
                            <div class="table_header">Wert</div>
                            <div class="table_header">Aktion</div>
                        </div>
                        <div class="table_row" v-for="statValueItem in statTemplate.statData" v-bind:key="statValueItem.id">
                            <div class='table_small'>
                                <div class='table_cell'>Datum</div>
                                <div class='table_cell'>{{ statValueItem.date }}</div>
                            </div>
                            <div class='table_small'>
                                <div class='table_cell'>Wert</div>
                                <div class='table_cell'>{{ statValueItem.value }}</div>
                            </div>
                            <div class='table_small'>
                                <div class='table_cell'>Aktion</div>
                                <div class='table_cell'>
                                    <font-awesome-icon class="svg" icon="edit" @click="showEditModal(statValueItem)" />
                                    <font-awesome-icon class="svg delete-icon" icon="times" @click="showDeleteModal(statValueItem)" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <p v-else>Es sind keine Daten vorhanden.</p>
                </div>
            </div>
            <div class="twelve columns">
                <button @click="navigate('Dashboard')" class="button-primary">Dashboard</button>
            </div>
        </div>
    </div>
</template>

<script>
import { LineChart, PieChart, BarChart } from 'vue-chart-3';
import SideNavigation from '../components/SideNavigation.vue'
import Modal from '../components/Modal.vue'
import EditStatDataModal from '../components/EditStatDataModal.vue'
import DeleteStatDataModal from '../components/DeleteStatDataModal.vue'
import Util from '../mixins/util'

export default {
    data () {
        return {
            name: 'Detailansicht',
            statTemplate: null,
            statValue: '',
            statDataPoint: {},
            statDate: new Date().toISOString().split('T')[0],
            chartID: this.$route.params.id,
            showEditStatDataModal: false,
            showDeleteStatDataModal: false,
            statValueType: null
        }
    },
    mounted: async function () {
        this.navigateDependingOnSession()
        this.statTemplate = await window.dataController.GetStatTemplateByID(this.chartID)
        if (this.statTemplate) {
            this.manageTextVisibility()
            this.statValueType = this.statTemplate.stat_unit
        }
    },
    methods: {
        manageTextVisibility: function () {
            let dateInput = this.$refs.statDate
            let dateInputPlaceholder = this.$refs.dateInputPlaceholder
            // console.log('dateInput', this.$refs.statDate)
            if (dateInput) {
                if (dateInput.value === '') {
                    dateInput.style.color = 'transparent'
                } else {
                    dateInputPlaceholder.style.top = '-5px'
                    dateInputPlaceholder.style.fontSize = '15px'
                    dateInput.style.color = '#555'
                }
            }
        },
        addDataToChart: async function () {
            let response = await window.dataController.AddDataToChart(
                {
                    chartID: this.chartID,
                    value: this.statValue,
                    date: this.statDate
                })
            if (response && response.status && response.status === 'ok') {
                this.statTemplate = await window.dataController.GetStatTemplateByID(this.chartID)
                if (this.statTemplate) {
                    window.dataController.ShowMessage('Der Datensatz wurde erfolgreich übertragen.', false, 'success')
                    this.statValue = ''
                    this.statDate = ''
                } else {
                    window.dataController.ShowMessage('Der Datensatz wurde übertragen aber der Graph nicht aktualisiert!', false, 'error')
                }
            } else {
                window.dataController.ShowMessage('Der Datensatz konnte nicht übertragen werden!', false, 'error')
            }
        },
        showEditModal: async function (dataPoint) {
            this.statDataPoint = dataPoint
            this.showEditStatDataModal = true
        },
        showDeleteModal: async function (dataPoint) {
            this.statDataPoint = dataPoint
            this.showDeleteStatDataModal = true
        },
        closeEditStatDataModal: function () {
            this.statValueID = null
            this.showEditStatDataModal = false
        },
        closeDeleteStatDataModal: function () {
            this.statValueID = null
            this.showDeleteStatDataModal = false
        },
        editSelectedDataPoint: async function (editData) {
            let result = await window.dataController.EditStatDataPoint(editData)
            if (result && result.status && result.status === 'ok') {
                this.closeEditStatDataModal()
                this.statTemplate = await window.dataController.GetStatTemplateByID(this.chartID)
                window.dataController.ShowMessage('Der Datensatz wurde erfolgreich aktualisiert.', false, 'success')
            } else {
                this.closeEditStatDataModal()
                window.dataController.ShowMessage('Der Datensatz konnte nicht aktualisiert werden!', false, 'error')
            }
        },
        deleteSelectedDataPoint: async function () {
            let result = await window.dataController.DeleteStatDataPoint(this.statDataPoint)
            if (result && result.status && result.status === 'ok') {
                this.closeDeleteStatDataModal()
                this.statTemplate = await window.dataController.GetStatTemplateByID(this.chartID)
                window.dataController.ShowMessage('Der Datensatz wurde erfolgreich gelöscht.', false, 'success')
            } else {
                this.closeDeleteStatDataModal()
                window.dataController.ShowMessage('Der Datensatz konnte nicht gelöscht werden!', false, 'error')
            }
        }
    },
    components: {
        LineChart, PieChart, BarChart,
        appSideNavigation: SideNavigation,
        appModal: Modal,
        appEditStatDataModal: EditStatDataModal,
        appDeleteStatDataModal: DeleteStatDataModal
    },
    mixins: [Util]
}

</script>
<style scoped>
.row-chart {
    background-color: white;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 2px 3px 10px 2px black;
}
div.main-content-container {
    box-sizing: border-box;
    min-height: 100vh;
    background-color: rgb(239 236 236 / 80%);
}
div.main-content-container button {
    margin-top: 0px;
    margin-bottom: 15px;
}
.row-chart{
    margin-top: 2%;
}
.row-chart p {
    margin: 0;
}
.row-chart .second{
    margin-top: 1%;
}
div.placeholder button.button-primary {
    float: right;
    margin-top: 0px;
}
div.input-div {
    position: relative;
    margin: 15px 0;
    padding: 5px 0;
    border-bottom: 2px solid #999;
}
.input-div:after, .input-div:before {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: #092A67;
    transition: .3s;
}
.input-div:after {
    right: 50%;
}
.input-div:before {
    left: 50%;
}
div.input-div.focus .icon i {
    color: #092A67;
}
.input-div.focus div h5 {
    top: -5px;
    font-size: 15px;
}
.input-div.focus:after, .input-div.focus:before {
    width: 50%;
}
div.icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
div.icon i {
    color: #999;
    transition: .3s;
}
div.input-div > div {
    position: relative;
    height: 45px;
}
div.input-div > div h5 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 18px;
    transition: 0.3s;
}
div.registration-body h5#salutation-header {
    margin-top: 15px;
    color: #999;
    font-size: 18px;
    transition: 0.3s;
}
.input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    outline: none;
    background: none;
    padding: 0.5rem 1rem;
    font-size: 16px;
    font-weight: bold;
    color: #555;
}
.input:focus {
    border: none;
}
div.form-submit {
    text-align: right;
}
.table {
    display: table;
    text-align: center;
    width: 100%;
    margin: 40px 0;
    border-collapse: separate;
    font-weight: 400;
}
.table_row {
    display: table-row;
    background: white;
    box-shadow: 2px 3px 10px 2px grey; 
}
.theader {
    display: table-row;
}
.table_header {
    display: table-cell;
    border-bottom: #476DB4 1px solid;
    border-top: #476DB4 1px solid;
    background: #476DB4;
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    border-right: 1px solid black;
    box-shadow: 2px 3px 10px 2px grey;
}
.table_header:first-child {
    border-left: #476DB4 1px solid;
    border-top-left-radius: 5px;
}
.table_header:last-child {
    border-right: #476DB4 1px solid;
    border-top-right-radius: 5px;
}
.table_small {
    display: table-cell;
    border-right: 1px solid;
}
.table_row > .table_small > .table_cell:nth-child(odd) {
    display: none;
    background: #476DB4;
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
}
.table_row > .table_small > .table_cell {
    padding: 10px 0;
    border-bottom: #ffffff 1px solid;
}
.table_row > .table_small:first-child > .table_cell {
    border-left: #ffffff 1px solid;
}
.table_row > .table_small:last-child > .table_cell {
    border-right: #ffffff 1px solid;
}
.table_cell svg {
    cursor: pointer;
    color: #222;
}
.table_cell svg.delete-icon {
    margin-left: 20px;
}
.table_row:last-child > .table_small:last-child > .table_cell:last-child {
    border-bottom-right-radius: 5px;
}
.table_row:last-child > .table_small:first-child > .table_cell:last-child {
    border-bottom-left-radius: 5px;
}
.table_row:nth-child(2n+3) {
    /* background: #e9e9e9; */
    background-color: rgb(239 236 236 / 80%);;
}
@media (min-width: 651px) {
    .table_row:hover {
        background-color: #F9A36B;
    }
}
@media (max-width: 550px) {
    .row-chart {
        width: 90%;
        margin: 5% auto;
    }
    div.main-content-container {
        padding: 12px;
    }
    .umsatzDiv,
    .monatDiv {
        width: 100%;
        display: block;
    }
    div.placeholder button.button-primary {
        float: none;
        margin-top: 30px;
        margin-left: 12%;
        width: 225px;
    }
}
@media (min-width: 550px) {
    div.row div.six.columns {
        width: 46%;
    }
}
@media screen and (max-width: 650px) {
    .table {
        display: block;
    }
    .table_row:nth-child(2n+3) {
        /* background: none; */
        background: rgba(255,255,255,0.8);
    }
    .theader {
        display: none;
    }
    .table_row > .table_small > .table_cell:nth-child(odd) {
        display: table-cell;
        width: 50%;
    }
    .table_cell {
        display: table-cell;
        width: 50%;
    }
    .table_row {
        display: table;
        width: 100%;
        border-collapse: separate;
        margin: 5% auto 0;
        text-align: center;
    }
    .table_small {
        display: table-row;
    }
    .table_row > .table_small:first-child > .table_cell:last-child {
        border-left: none;
    }
    .table_row > .table_small > .table_cell:first-child {
        border-left: #ccc 1px solid;
    }
    .table_row > .table_small:first-child > .table_cell:first-child {
        /* border-top-left-radius: 5px; */
        border-top: #ccc 1px solid;
    }
    .table_row > .table_small:first-child > .table_cell:last-child {
        /* border-top-right-radius: 5px; */
        border-top: #ccc 1px solid;
    }
    .table_row > .table_small:last-child > .table_cell:first-child {
        border-right: none;
    }
    .table_row > .table_small > .table_cell:last-child {
        border-right: #ccc 1px solid;
    }
}
</style>