<template>
  <div class="background-image">
    <appModal></appModal>
    <div class="login-body">
      <div class="login">
        <img class="vilna-logo" src="../assets/logo_blue_shadow.png" alt="">
        <div class="input-div one">
          <div class="icon">
            <i class="fas fa-user"></i>
          </div>
          <div>
            <h5>E-Mail</h5>
            <input class="input" v-model="userMail" type="text" id="user" @focus="focusFunction" @blur="blurFunction">
          </div>
        </div>
        <div class="input-div two">
          <div class="icon">
            <i class="fas fa-lock"></i>
          </div>
          <div>
            <h5>Passwort</h5>
            <input class="input" v-model="password" type="password" id="pass" @focus="focusFunction" @blur="blurFunction">
          </div>
        </div>
        <button @click="login" class="btn" id="login">Anmelden</button><br>
        <div class="link-container">
          <button @click="toRegistration" class="pass">Registrierung</button>
          <button class="pass">Passwort vergessen?</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '../components/Modal.vue'
import Util from '../mixins/util'

export default {
  data: () =>({
    userMail: '',
    password: ''
  }),
  mounted: function () {
    var isLogged = localStorage.getItem('isLogged')
    console.log('isLogged', isLogged)
    if (isLogged) {
      this.$router.push({name: 'Dashboard'});
    }
  },
  methods: {
    login: async function () {
      if (this.userMail !== '' && this.password !== '') {
        var session = await window.dataController.Login({mail: this.userMail, pw: this.password})
        if (session) {
          localStorage.setItem('isLogged', true);
          this.$router.push({name: 'Dashboard'});
        }
      }
    },
    toRegistration: function () {
      this.$router.push({name: 'Registration'});
    }
  },
  components: {
    appModal: Modal
  },
  mixins: [Util]
}
</script>
<style scoped>
.background-image {
  background-image: url('/chart.jpg') ;
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-body {
  background-color:rgba(255,255,255,0.8);
  border-radius:25px;
  padding: 25px;
  width: 300px;
  align-self: center;
  box-shadow: 25px 15px 10px rgb(0 0 0 / 40%);
}
img.vilna-logo {
  height: 150px;
  align-self: center;
  margin-bottom: 20px;
}
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#user,
#pass {
  width: 100%;
  align-self: center;
}
button.pass {
  border: 0;
  color: #092A67;
  padding: 0;
  margin: 0;
  text-transform: none;
  font-size: 14px;
}
button.pass:hover {
  color: #476DB4;
}
button#login, button.pass {
  transition: all 0.6s ease;
}
div.link-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
div.input-div {
  position: relative;
  display: grid;
  grid-template-columns: 7% 93%;
  margin: 25px 0;
  padding: 5px 0;
  border-bottom: 2px solid #999;
}
.input-div:after, .input-div:before {
  content: '';
  position: absolute;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #092A67;
  transition: .3s;
}
.input-div:after {
  right: 50%;
}
.input-div:before {
  left: 50%;
}
div.input-div.focus .icon i {
  color: #092A67;
}
.input-div.focus div h5 {
  top: -5px;
  font-size: 15px;
}
.input-div.focus:after, .input-div.focus:before {
  width: 50%;
}
div.input-div.one {
  margin-top: 0;
}
div.input-div.two {
  margin-bottom: 4px;
}
div.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.icon i {
  color: #999;
  transition: .3s;
}
div.input-div > div {
  position: relative;
  height: 45px;
}
div.input-div > div h5 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  transition: 0.3s;
}
.input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: bold;
  color: #555;
}
.input:focus {
  border: none;
}
.btn {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  margin: 4rem 0 1rem 0;
  font-size: 1.2rem;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #092A67, #476DB4);
  cursor: pointer;
  color: #ffffffff;
  text-transform: uppercase;
  background-size: 200%;
  transition: .5s;
}
.btn:hover {
  background-position: right;
}
</style>
