<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
div.content {
  display: block;
  height: 100%;
}
div#app {
  /* background-color: rgba(255, 255, 255, 0.2); */
  height:100%;
}
body {
  /* background-image: url("/chart.jpg"); */
}
div.main-content-container {
  padding: 20px 30px 20px 100px;
  height: 100%;
  box-sizing: border-box;
}
div.main-content-container-closed {
  box-sizing: border-box;
  padding: 20px 30px 20px 100px;
  height: 100vh;
}
.site-header {
  /* padding-top: 20px; */
  height: 5%;
  text-align: left;
}
.site-header h3 {
  font-weight: bold;
}
div.site-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
