<template>
  <div class="content" id="dashboard">
    <appSideNavigation></appSideNavigation>
    <div class="main-content-container">
      <div class="site-header">
        <h3>{{name}}</h3>
      </div>
      <div class="row chart" v-for="template in statTemplates" v-bind:key="template.id">
        <div class="row">
          <div class="twelve columns small-detail-container" :id="getSmallDetailContainerId(template.id)">
            <h5>{{template.name}}</h5>
            <div class="action-container">
              <button @click="toDetails(template.id)" class="button-primary details-button">Details</button>
              <button class="button-primary btn-icon" @click="toggleGraphContainer(template.id)">
                  <font-awesome-icon class="svg" icon="arrow-down" size="2x" :id="getArrowIconId(template.id)" />
              </button>
            </div>
          </div>
        </div>
        <div class="row graph-container" :id="getGraphContainerID(template.id)">
          <div class="eight columns">
            <LineChart v-if='JSON.parse(template.config).chartType === "line"' :chartData="JSON.parse(template.config).chart" :options="JSON.parse(template.config).options ? JSON.parse(template.config).options: {}" />
            <PieChart v-else-if='JSON.parse(template.config).chartType === "pie"' :chartData="JSON.parse(template.config).chart" :options="JSON.parse(template.config).options ? JSON.parse(template.config).options: {}"/>
            <BarChart v-else-if='JSON.parse(template.config).chartType === "bar"' :chartData="JSON.parse(template.config).chart" :options="JSON.parse(template.config).options ? JSON.parse(template.config).options: {}"/>
          </div>
          <div class="four columns chart-info-container">
            <div class="chart-info-data">
              <h5>Beschreibung</h5>
              <p id="datensatz-desciption">{{template.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { LineChart, PieChart, BarChart } from 'vue-chart-3';
import SideNavigation from '../components/SideNavigation.vue';
import Util from '../mixins/util'

export default {
  data () {
    return {
      name: 'Dashboard',
      statTemplates: [],
      processedStatTemplates: []
    }
  },
  mounted: async function () {
    this.navigateDependingOnSession()
    this.statTemplates = await window.dataController.GetStatTemplates()
  },
  methods: {
    getGraphContainerID: function (graphID) {
      return 'graph-container-' + graphID
    },
    getArrowIconId: function (graphID) {
      return 'arrow-icon-' + graphID
    },
    getSmallDetailContainerId: function (graphID) {
      return 'small-detail-container-' + graphID
    },
    toggleGraphContainer: function (graphID) {
      let container = document.querySelector('#graph-container-' + graphID)
      let icon = document.querySelector('#arrow-icon-' + graphID)
      let smallDetailContainer = document.querySelector('#small-detail-container-' + graphID)
      if (container) {
        if (container.style.maxHeight === '0px' || container.style.maxHeight === '') {
          container.style.maxHeight = '2000px'
          icon.style.transform = 'rotate(180deg)'
          smallDetailContainer.style.marginBottom = '20px'
        } else {
          container.style.maxHeight = '0px'
          icon.style.transform = 'rotate(360deg)'
          smallDetailContainer.style.marginBottom = '0px'
        }
      }
    },
    toDetails: function (id) {
      this.$router.push({ name: 'UserChart', params: { id: id}})
    }
  },
  components: { LineChart, PieChart, BarChart, appSideNavigation: SideNavigation},
  mixins: [Util]
};

</script>
<style scoped>
.chart {
  background-color: white;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 2px 3px 10px 2px black;
}
.chart-info-container {
  text-align: left;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chart-info-container h5 {
  text-align: center;
}
.chart-info-button {
  text-align: right;
}
div.graph-container {
  max-height: 0px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
div.main-content-container {
  background-color: rgb(239 236 236 / 80%);
}
div.small-detail-container {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;
}
div.action-container {
  width: 30%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
}
button.btn-icon {
  border-color: #F26627;
  background-color: #F26627;
  width: 40px;
  padding: 0;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
button.btn-icon svg {
  margin: auto 0;
  transition: all 0.5s ease-in-out;
}
/* Smaller than phablet */
@media (max-width: 550px) {
  .chart-info-container {
    margin-bottom: 0;
    margin-top: 30px;
    height: auto;
  }
  .chart-info-button {
    text-align: center;
  }
  .chart {
    padding: 8px;
  }
}
@media (max-width: 992px) {
  div.action-container {
    width: 50%;
  }
  button.details-button {
    width: 60%;
  }
}
</style>
