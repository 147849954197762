<template>
  <div class="navigation-container">
    <div v-if="isMobile" class="side-navigation-mobile">
      <div class="mobileSideNavigation">
        <img class="vilnaLogo" src="../assets/logo_blue_shadow.png" alt="Vilna">
        <font-awesome-icon v-if="!mobileIsOpen" @click="toggleNavigation" class="svg" icon="bars" size="2x" />
        <font-awesome-icon v-else @click="toggleNavigation" class="svg" icon="times" size="2x" />
      </div>
      <div class="mobile-navigation-content">
        <nav class="navMo__cont">
          <ul class="navMo">
            <li class="navMo__items">
              <router-link  to="/dashboard">
                <font-awesome-icon class="svg" icon="chart-line" size="2x" />
                Dashboard
              </router-link>
            </li>
            <li class="navMo__items">
              <router-link to="/anmeldedaten">
                <font-awesome-icon class="svg" icon="user" size="2x" />
                Daten ändern
              </router-link>
            </li>
            <li class="navMo__items">
              <router-link to="/benutzeroverview">
                <font-awesome-icon class="svg" icon="users" size="2x" />
                Benutzer
              </router-link>
            </li>
            <li class="navMo__items">
              <a @click="Logout">
                <font-awesome-icon class="svg" icon="power-off" size="2x" />
                Logout
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div v-else class="side-navigation">
        <nav class="nav__cont">
          <ul class="nav">
            <li class="nav__items">
              <router-link  to="/dashboard">
                <font-awesome-icon class="svg" icon="chart-line" size="2x" />
                Dashboard
              </router-link>
            </li>
            <li class="nav__items">
              <router-link to="/anmeldedaten">
                <font-awesome-icon class="svg" icon="user" size="2x" />
                Daten ändern
              </router-link>
            </li>
            <li class="nav__items" v-if="isOwner">
              <router-link to="/benutzeroverview">
                <font-awesome-icon class="svg" icon="users" size="2x" />
                Benutzer
              </router-link>
            </li>
            <li class="nav__items">
              <a @click="Logout">
                <font-awesome-icon class="svg" icon="power-off" size="2x" />
                Logout
              </a>
            </li>
          </ul>
        </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideNavigation',
  data: () =>({
    mobileIsOpen: false,
    isMobile: false,
    isOwner: false
  }),
  mounted: async function () {
    this.isMobile = this.mobileCheck()
    this.isOwner = window.dataController.data.session.isOwner === 1 ? true : false
  },
  methods: {
    Logout: async function () {
      if (localStorage.getItem('isLogged')) {
        var res = await window.dataController.Logout()
        if (res) {
          localStorage.removeItem('isLogged');
          this.$router.push({name: 'Login'});
        }
      }
    },
    toggleNavigation: function () {
      let navigation = document.querySelector('.mobile-navigation-content');
      let navigationCont = document.querySelector('.navMo__cont')
      if((navigation.style.top === '' || navigation.style.top === '-100%') && this.mobileIsOpen === false) {
        navigation.style.top = '35px';
        navigationCont.style.height = '100vh';
        this.mobileIsOpen = true
      } else {
        navigation.style.top = '-100%';
        navigationCont.style.height = '0vh';
        this.mobileIsOpen = false
      }
    },
    mobileCheck: function () {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.nav__cont {
  position: fixed;
  width: 70px;
  top:0;
  height: 100vh;
  z-index: 100;
  background-color: #092A67;
  overflow:hidden;
  transition: width .3s ease;
  cursor:pointer;
  box-shadow:4px 7px 10px rgba(0,0,0,.4);
  }
.nav__cont:hover {
  width:250px;
}
.nav {
  list-style-type: none;
  color:white;
  padding-left: 35px;
  }
.nav:first-child {
  padding-top:11.5rem;
}
.nav__items {
  padding-bottom:0rem;
  font-family: 'Roboto',sans-serif;
  padding-top: 50px;
}
nav a {
  position: relative;
  display:inline-flex;
  flex-direction: row;
  top:-35px;
  padding-left:25px;
  padding-right:15px;
  transition:all .3s ease;
  margin-left:25px;
  margin-right:10px;
  text-decoration: none;
  color:white;
  font-family: 'Roboto',sans-serif;
  font-weight: 100;
  font-size: 1.35em;
  white-space: nowrap;
}
nav a:after {
  content:'';
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  border-radius:2px;
  background:#476DB4;
  opacity:0;
  transition:all .5s ease;
  z-index: -10;
}
nav i,
nav .svg {
  width:30px;
  height:30px;
  position: absolute;
  left:-40px;
  cursor:pointer;
  top: 4px;
}
h1 {
  font-family: 'Roboto',sans-serif;
}
.router-link-active.router-link-exact-active,
li.nav__items >i,
.nav__items:hover a {
color: #F26627;
}

div.mobileSideNavigation {
  width: auto;
  height: 6vh;
  background-color: #092A67;
  color: white;
  position: sticky;
  z-index: 110;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.vilnaLogo {
  color: white;
  width: auto;
  height: 100%;
  margin-left: 15px;
  align-self: center;
}
div.mobileSideNavigation .svg {
  align-self: center;
  margin-right: 15px;
  cursor: pointer;
}
.mobile-navigation-content{
  top: -100%;
  position: absolute;
  z-index: 100;
  width: 100vw;
  left: 0px;
  background-color: #092A67;
  transition: all 0.3s ease-out;
}
.navMo__cont{
  /* margin-top: 38px; */
  width: auto;
  /* height: 100vh; */
  z-index: 100;
  cursor:pointer;
  /* transition:box-shadow 0.2s ease-out; */
  box-shadow:4px 7px 10px rgba(0,0,0,.4);
}
.navMo {
  list-style-type: none;
}
.navMo__items {
  padding-bottom:0rem;
  font-family: 'Roboto',sans-serif;
  padding-top: 50px;
  font-size: 2rem;
  /* box-shadow:4px 7px 10px rgba(0,0,0,.4); */
}
</style>

