import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Chart, registerables } from 'chart.js';
import { DataController } from './DataController.js'
Chart.register(...registerables);
library.add(fas)

window.dataController = new DataController()

const vilna = createApp(App)
vilna.component('font-awesome-icon', FontAwesomeIcon)
vilna.use(router)
vilna.mount('#app')
