<template>
    <div v-if="show" class="app-delete-stat-data-modal">
        <div class="modal-background">
            <div class="modal-container">
                <div class="modal-header">
                    <font-awesome-icon class="svg" icon="times" size="2x" @click="closeModal" />
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="twelve columns message-container">
                            <h5>Datenpunkt löschen?</h5>
                        </div>
                    </div>
                </div>
                <div id="app-modal-footer" class="modal-footer">
                    <div class="row">
                        <div class="six columns">
                            <button @click="closeModal" class="button-primary close-button">Nein</button>
                        </div>
                        <div class="six columns save-button">
                            <button @click="deleteDataPoint()" class="button-primary">Ja</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Util from '../mixins/util'

export default {
name: 'Datenpunkt löschen',
    data () {
        return {
        }
    },
    props: ['show'],
    created () {
        window.addEventListener('scroll', this.handleModalScroll('app-delete-stat-data-modal'))
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleModalScroll('app-delete-stat-data-modal'))
    },
    methods: {
        closeModal: function () {
            this.$parent.closeDeleteStatDataModal()
        },
        deleteDataPoint: function () {
            this.$parent.deleteSelectedDataPoint()
        }
    },
    mixins: [Util]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.app-delete-stat-data-modal {
    z-index: 1;
}
.modal-container {
    z-index: 10;
    border: 2px solid black;
    background-color: #ffffff;
    height: 23vh;
    width: 50%;
    align-self: center;
    padding: 1vh 2vh;
    border-radius: 20px;
}
.modal-background {
    z-index: 10;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(50,50,50,0.5);
    display: flex;
    justify-content: center;
}
.modal-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
}
.message-container {
    text-align: center;
}
.message-container h5 {
    margin: 0;
}
.modal-header svg {
    align-self: center;
    color: #263c8e;
    cursor: pointer;
}
.modal-body {
    overflow: auto;
    height: 12vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.modal-body .row.input-container {
    text-align: center;
}
.modal-message {
    font-size: 16px;
    align-self: center;
    margin: 0;
    color: red;
}
.modal-footer {
    height: 4vh;
}
.modal-footer .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.modal-footer .row button {
    width: 35%;
    padding: 0 10px;
}
.close-button {
    background-color: #F9A36B;
    border-color: #F9A36B;
}
.close-button:hover {
    background-color: #F26627;
    border-color: #F26627;
}
.save-button {
    text-align: right;
}
@media screen and (max-width: 900px) {
    .modal-container {
        width: 60%;
    }
}
@media (max-width: 550px) {
    .button-container button {
        width: 100%;
    }
    .modal-container {
        width: 80%;
        height: 15vh;
    }
    .modal-body {
        justify-content: flex-start;
        height: 5vh;
    }
}
</style>
