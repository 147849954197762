<template>
    <div v-if="show" class="app-edit-stat-data-modal">
        <div class="modal-background">
            <div class="modal-container">
                <div class="modal-header">
                    <font-awesome-icon class="svg" icon="times" size="2x" @click="closeModal" />
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="twelve columns message-container">
                            <h5>Datenpunkt editieren</h5>
                            <p id="app-edit-stat-value-modal-message" class="modal-message"></p>
                        </div>
                    </div>
                    <div class="row input-container">
                        <div class="six columns">
                            <div class="input-div one focus">
                                <div>
                                    <h5>Wert</h5>
                                    <input v-if="statValueType === '1'" class="input" v-model="newValue" type="text" id="statDataInput" @focus="focusFunction" @blur="blurFunction">
                                    <input v-else-if="statValueType === '2'" class="input" type="number" id="stat-value" min="0" max="100" step="1" v-model="newValue" @focus="focusFunction" @blur="blurFunction" @change="validatePercentageInput">
                                    <input v-else class="input" type="number" id="stat-value" min="0" step="1" v-model="newValue" @focus="focusFunction" @blur="blurFunction">
                                </div>
                            </div>
                        </div>
                        <div class="six columns">
                            <div class="input-div two focus">
                                <div>
                                    <h5>Datum</h5>
                                    <input class="input" v-model="newDate" type="date" id="statDataInputDate" @focus="focusFunction" @blur="blurFunction">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="app-modal-footer" class="modal-footer">
                    <div class="row">
                        <div class="six columns">
                            <button @click="closeModal" class="button-primary close-button">Abbrechen</button>
                        </div>
                        <div class="six columns save-button">
                            <button @click="editDataPoint()" class="button-primary">Ändern</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Util from '../mixins/util'

export default {
name: 'Datenpunkt ändern',
    data () {
        return {
            newValue: '',
            newDate: ''
        }
    },
    props: ['show', 'statDataPoint', 'statValueType'],
    created () {
        window.addEventListener('scroll', this.handleModalScroll('app-edit-stat-data-modal'))
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleModalScroll('app-edit-stat-data-modal'))
    },
    mounted: function () {

    },
    methods: {
        closeModal: function () {
            this.newValue = ''
            this.$parent.closeEditStatDataModal()
        },
        editDataPoint: function () {
            if (this.newValue !== '' && this.newDate !== '') {

                if (this.statValueType === '2') {
                    if (this.newValue > 100 || this.newValue < 0) {
                        this.showEditStatDataMessage('Bitte geben Sie einen Wert zwischen 0 und 100 ein.')
                    } else {
                        this.$parent.editSelectedDataPoint({id: this.statDataPoint.id, date: this.newDate, value: this.newValue})
                    }
                } else {
                    this.$parent.editSelectedDataPoint({id: this.statDataPoint.id, date: this.newDate, value: this.newValue})
                }
            } else {
                this.showEditStatDataMessage('Bitte geben Sie einen Wert und ein Datum ein.')
            }
        },
        showEditStatDataMessage: function (text) {
            let message = document.querySelector('#app-edit-stat-value-modal-message')
            if (message) {
                message.innerHTML = text
            }
        },
        validatePercentageInput: function (event) {
            if (event.target.value > 100) {
                event.target.value = 100
            } else {
                if (event.target.value < 0) {
                    event.target.value = 0
                }
            }
        }
    },
    watch: {
        show: function () {
            if (this.statDataPoint) {
                this.newValue = this.statDataPoint.value
                this.newDate = this.parseDateFromGermanToEnglish(this.statDataPoint.date).toISOString().split('T')[0]
            }
        }
    },
    mixins: [Util]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.app-edit-stat-data-modal {
    z-index: 1;
}
.modal-container {
    z-index: 10;
    border: 2px solid black;
    background-color: #ffffff;
    height: 28vh;
    width: 50%;
    align-self: center;
    padding: 1vh 2vh;
    border-radius: 20px;
}
.modal-background {
    z-index: 10;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(50,50,50,0.5);
    display: flex;
    justify-content: center;
}
.modal-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
}
.message-container {
    text-align: center;
}
.message-container h5 {
    margin: 0;
}
.modal-header svg {
    align-self: center;
    color: #263c8e;
    cursor: pointer;
}
.modal-body {
    overflow: auto;
    height: 17vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.modal-body .row.input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.modal-body .row.input-container .columns {
    width: 45%;
    margin: 0;
}
.modal-message {
    font-size: 20px;
    align-self: center;
    margin: 0;
    color: red;
}
.modal-footer {
    height: 4vh;
}
.modal-footer .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
div.input-div {
    position: relative;
    margin: 15px 0;
    padding: 5px 0;
    border-bottom: 2px solid #999;
}
.input-div:after, .input-div:before {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: #092A67;
    transition: .3s;
}
.input-div:after {
    right: 50%;
}
.input-div:before {
    left: 50%;
}
div.input-div.focus .icon i {
    color: #092A67;
}
.input-div.focus div h5 {
    top: -5px;
    font-size: 15px;
}
.input-div.focus:after, .input-div.focus:before {
    width: 50%;
}
div.icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
div.icon i {
    color: #999;
    transition: .3s;
}
div.input-div > div {
    position: relative;
    height: 45px;
}
div.input-div > div h5 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 18px;
    transition: 0.3s;
}
.input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    outline: none;
    background: none;
    padding: 0.5rem 1rem;
    font-size: 16px;
    font-weight: bold;
    color: #555;
}
.input:focus {
    border: none;
}
.close-button {
    background-color: #F9A36B;
    border-color: #F9A36B;
}
.close-button:hover {
    background-color: #F26627;
    border-color: #F26627;
}
.save-button {
    text-align: right;
}
@media screen and (max-width: 900px) {
    .modal-container {
        width: 60%;
    }
}
@media (max-width: 550px) {
    .modal-footer button {
        width: 75%;
        padding: 0 10px;
    }
    .modal-container {
        width: 80%;
        height: 30vh;
    }
    .modal-body .row.input-container .columns {
        width: 100%;
    }
    .modal-body {
        justify-content: flex-start;
        height: 19vh;
    }
}
</style>
